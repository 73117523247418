import { getId } from "@core/utils/Tools";
import { Box, InputProps, BoxProps, TooltipProps } from "@mui/material";
import TotalTooltip from "@presentation/components/TotalTooltip";
import { InputLabel } from "@presentation/components/inputs/atoms/InputLabel";
import { InputTextField } from "@presentation/components/inputs/atoms/InputTextField";
import { useTranslation } from "react-i18next";

type TextFieldDataProps = Omit<BoxProps, "onChange"> &
  Pick<InputProps, "disabled" | "error" | "value" | "type" | "endAdornment"> & {
    label?: string;
    width?: string;
    color?: string;
    inputProps?: InputProps;
    onChange: (e: string) => void;
    tooltipProps?: TooltipProps;
  };

export const TextFieldData = ({
  disabled,
  label,
  error,
  placeholder,
  onChange,
  width,
  color = "white",
  value,
  type,
  inputProps,
  endAdornment,
  sx,
  tooltipProps,
  ...props
}: TextFieldDataProps): React.JSX.Element => {
  const { t } = useTranslation();

  return (
    <TotalTooltip title={t("input_text.hover_alert")} {...tooltipProps}>
      <Box sx={{ width: width, ...sx }} {...props}>
        <InputLabel label={label} />
        <InputTextField
          id={getId("input", props?.id)}
          onChangeCustom={onChange}
          placeholder={placeholder}
          color={color}
          value={value}
          type={type}
          endAdornment={endAdornment}
          {...inputProps}
        />
      </Box>
    </TotalTooltip>
  );
};
